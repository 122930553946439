import SkipSection from "@components/SkipSection";
import {FeatureFlag} from "@services/featureFlagConstants";
import {identity} from "lodash";
import moment from "moment";
import {useTranslation} from "ni18n";
import React, {ReactElement, useEffect, useState} from "react";
import {Pred} from "src/extensions";
import {useTypedSelector} from "src/store";
import {RootStateLocation} from "src/store/types";
import useMediaQuery from "src/useMediaQuery";

import {getNearbyDoctors, loadAvailableSlots} from "../../_services/api";
import {Doctor} from "../../_services/types";
import {useFeatureFlag} from "../../hooks/useFeatureFlags";
import {isCI} from "../_common/_constants";
import {useWindowSize} from "../_common/Carbon";
import {LazyComp} from "../_common/LazyComp";
import OtherProvider from "./OtherProvider";

type Props = {
  tag?: string;
  header?: string;
  coordinate: {
    longitude: number;
    latitude: number;
  };
  radius?: number;
  filter?: Pred<ExtendedDoctor>;
};

export interface ExtendedDoctor extends Doctor {
  primaryLocation?: RootStateLocation;
  nextApptSlot?: string | number;
}

const OtherProviders = ({
  header,
  tag,
  coordinate,
  filter = identity,
  radius = 3,
}: Props): ReactElement => {
  const i18n = useTranslation();
  const {width} = useWindowSize();
  const isSm = useMediaQuery("sm");
  const {locations} = useTypedSelector(state => state.config);
  const [nearbyDoctors, setNearbyDoctors] = useState([]);
  const [doctorsWithSlots, setDoctorsWithSlots] = useState([]);
  const enableCache = useFeatureFlag<boolean>(FeatureFlag.GROWTH_CACHED_SLOTS_ENABLED);

  useEffect(() => {
    getNearbyDoctors(coordinate.latitude, coordinate.longitude, radius).then(doctors => {
      if (!doctors) return;
      // @ts-expect-error TS2345: Argument of type 'Doctor[]' is not assignable to parameter of type 'SetStateAction<never[]>'.
      setNearbyDoctors(doctors.filter(d => d.hasPublicLandingPage));
    });
  }, [coordinate.latitude, coordinate.longitude, radius]);

  useEffect(() => {
    nearbyDoctors
      .filter(filter)
      .map(async (p: Doctor): Promise<ExtendedDoctor> => {
        const slots = await loadAvailableSlots({
          practiceId: p.practiceId,
          from: +moment(),
          to: +moment().add(7, "days").endOf("day"),
          duration: 20,
          doctorId: p.id,
          enableCache,
        });
        return {
          ...p,
          primaryLocation: locations.findById(p.primaryLocationId),
          nextApptSlot: slots.flat()?.[0]?.time,
        };
      })
      .sequence()
      .then(results => {
        // @ts-expect-error TS2345: Argument of type 'ExtendedDoctor[]' is not assignable to parameter of type 'SetStateAction<never[]>'.
        setDoctorsWithSlots(results as ExtendedDoctor[]);
      });
  }, [locations, nearbyDoctors]);

  const profileWidth = isSm ? 362 : 412;
  const leftPadding = 37;
  // @ts-expect-error TS2532: Object is possibly 'undefined'.
  const canProvidersScroll = width < profileWidth * doctorsWithSlots.length + leftPadding;
  // @ts-expect-error TS2532: Object is possibly 'undefined'.
  const profilesPerScroll = Math.floor(width / profileWidth);

  const scrollRight = (): void => {
    const providersContainer = document.getElementById("providers");
    // @ts-expect-error TS2531: Object is possibly 'null'.
    providersContainer.scrollBy(profileWidth * profilesPerScroll, 0);
  };

  const scrollLeft = (): void => {
    const providersContainer = document.getElementById("providers");
    // @ts-expect-error TS2531: Object is possibly 'null'.
    providersContainer.scrollBy(0 - profileWidth * profilesPerScroll, 0);
  };

  const filteredDoctors = doctorsWithSlots.filter(filter);

  // @ts-expect-error TS2322: Type 'false | Element' is not assignable to type 'ReactElement<any, string | JSXElementConstructor<any>>'.
  return (
    !filteredDoctors.isEmpty() && (
      <section
        className="bg-gray50 oh pv24 contrast-tbt"
        aria-label={i18n.t("Our primary care providers")}
      >
        <div className="pos-r pl7 hide-scroll-bars">
          <div className="df aife fdc-sm aifs-sm jcsb ph20 ph8-md ph6-sm ph3-xs pb10">
            <div>
              <strong className="fs12 font-mr ttu ls1 mv7-f gray600 db lh14">
                {tag || i18n.t("Our primary care providers")}
              </strong>
              <h2 className="mb4 fs40 fs32-md fs28-sm maxw35 maxw30-md font-csb lh1 pb4">
                {header || [
                  <span key="header1">
                    {i18n.t("World-class providers. Inclusive healthcare.")}{" "}
                  </span>,
                  <span key="header2" className="darkGreen">
                    {i18n.t("A health team centered around you.")}
                  </span>,
                ]}
              </h2>
            </div>

            {filteredDoctors.length > 1 && canProvidersScroll && (
              <div className="jsfe mb8 mb4-sm mt8-sm">
                <SkipSection
                  hrefs={["insurance-heading", "footer"]} // id="explore-locations" may not exist in dom, fallback to id="footer"
                  style={isSm ? {top: "9rem"} : {top: "4rem"}}
                >
                  {i18n.t("Skip Carousel")}
                </SkipSection>
                <button
                  aria-label={i18n.t("Scroll left.")}
                  className="bg-gray800 br50 cp p2 cIcon-arrow-left mr4 otn brdn white fs30 contrast-tb"
                  style={{width: 53, height: 53}}
                  onClick={scrollLeft}
                />
                <button
                  aria-label={i18n.t("Scroll right.")}
                  className="bg-gray800 br50 cp p2 cIcon-arrow-left otn brdn rotate-180 white fs30 contrast-tb"
                  style={{width: 53, height: 53}}
                  onClick={scrollRight}
                />
              </div>
            )}
          </div>

          <div id="providers" className="df" style={{overflow: "scroll", scrollBehavior: "smooth"}}>
            {filteredDoctors.map(p => (
              // @ts-expect-error TS2339: Property 'id' does not exist on type 'never'.
              <div key={p.id} className="mr8">
                <OtherProvider doctor={p} />
              </div>
            ))}
          </div>
        </div>
      </section>
    )
  );
};

const OtherProvidersWrapper = (props: Props): ReactElement => (
  <LazyComp threshold={750} visibleByDefault={Boolean(isCI)}>
    <OtherProviders {...props} />
  </LazyComp>
);

export default OtherProvidersWrapper;
